type SvgInHtml = HTMLElement & SVGElement;

const svg = document.getElementById("chart-svg") as SvgInHtml;

let svgSize = [svg.clientWidth, svg.clientHeight];

const randomNumBetween = (min, max) =>
    Math.floor(Math.random() * (max - min)) + min;

let cubeIds = [...Array(60).keys()].map((val, index) => index);

const rangeInput = document.getElementById("animation-speed") as HTMLInputElement;

let animationSpeed = +rangeInput.value;

rangeInput.addEventListener("input", (event) => {
  // @ts-ignore
    animationSpeed = +event.target.value;
});

window.addEventListener("resize", () => {
    svgSize = [svg.clientWidth, svg.clientHeight];
});

const randomWidth = () => randomNumBetween(30, svgSize[1]);

function setRandomAttributes(rect) {
    const x = randomNumBetween(-svgSize[0], svgSize[0]);
    const y = randomNumBetween(-svgSize[1], svgSize[1]);
    const width = randomWidth();
    const height = randomWidth();

    rect.setAttributeNS(null, "x", `${x}`);
    rect.setAttributeNS(null, "y", `${y}`);
    rect.setAttributeNS(null, "height", `${height}`);
    rect.setAttributeNS(null, "width", `${width}`);

    return { x, y, width, height };
}

for (const cubeId of cubeIds) {
    const rect = document.createElementNS("http://www.w3.org/2000/svg", "rect");
    rect.id = String(cubeId);

    setRandomAttributes(rect);

    rect.setAttributeNS(null, "stroke", "white");
    if (cubeId === 40) rect.setAttributeNS(null, "fill", "url(#gradient)");
    svg.append(rect);
}

function animateSvg() {
    requestAnimationFrame(animateSvg);

    for (const cubeId of cubeIds) {
        const rect = document.getElementById(String(cubeId));

        if (rect !== selectedElement) {
            let x = +rect.getAttributeNS(null, "x") + animationSpeed * cubeId;

            if (x > svgSize[0]) {
                const { width } = setRandomAttributes(rect);
                x = -width;
            }

            rect.setAttributeNS(null, "x", String(x));
        }
    }
}

let selectedElement, offset;

svg.addEventListener("mousedown", startDrag);
svg.addEventListener("mousemove", drag);
svg.addEventListener("mouseup", endDrag);
svg.addEventListener("mouseleave", endDrag);

function getMousePosition(evt) {
  // @ts-ignore
    const CTM = svg.getScreenCTM();
    return {
        x: (evt.clientX - CTM.e) / CTM.a,
        y: (evt.clientY - CTM.f) / CTM.d,
    };
}

function startDrag(evt) {
    selectedElement = evt.target;

    offset = getMousePosition(evt);
    offset.x -= parseFloat(selectedElement.getAttributeNS(null, "x"));
    offset.y -= parseFloat(selectedElement.getAttributeNS(null, "y"));
}

function drag(evt) {
    if (selectedElement) {
        evt.preventDefault();
        const coord = getMousePosition(evt);
        selectedElement.classList.add("grabbing");
        selectedElement.setAttributeNS(null, "x", `${coord.x - offset.x}`);
        selectedElement.setAttributeNS(null, "y", `${coord.y - offset.y}`);
    }
}

function endDrag() {
    selectedElement && selectedElement.classList.remove("grabbing");
    selectedElement = null;
}

animateSvg();

export {};